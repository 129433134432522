import { API_URL } from "./const";
import { Course, Module } from "./Models/Course";
import { Access, ModuleAccesses } from "./Models/ModuleAccess";
import trackingInstance from "./Tracking/Tracking";
import { TRACKING_EVENTS } from "./Tracking/TrackingEvents";

export const secondsToHms = (
    seconds: string | number,
    includeSeconds: boolean = true
) => {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const hDisplay = h > 0 ? h + "hr " : "";
    const mDisplay = m > 0 ? m + "min " : "";
    const sDisplay = includeSeconds && s > 0 ? s + "sec" : "";
    return hDisplay + mDisplay + sDisplay;
};

export const hasPermanentAccess = (
    module: Module,
    moduleAccess: { [key: string]: Access }
) => {
    if (moduleAccess[module.id]) {
        if (moduleAccess[module.id].accessType === "permanent") {
            return true;
        }
    }
    return false;
};

export const getCollectionNetPrice = (
    collection: Course,
    moduleAccess: { [key: string]: Access },
    isAuthenticated: boolean
) => {
    let totalPrice = 0;
    let bundleDiscount = parseFloat(collection.bundleDiscount);

    // Apply promotion discount
    if (collection.promotion) {
        bundleDiscount += parseFloat(collection.promotion.promoDiscount);
    }

    collection.modules.forEach((module) => {
        if (!isAuthenticated || !hasPermanentAccess(module, moduleAccess)) {
            totalPrice += Math.ceil(module.price * (1 - bundleDiscount));
        }

        module.licensedModules.forEach((licensedModule) => {
            if (
                !isAuthenticated ||
                !hasPermanentAccess(licensedModule, moduleAccess)
            ) {
                totalPrice += Math.ceil(
                    licensedModule.price * (1 - bundleDiscount)
                );
            }
        });
    });

    return totalPrice;
};

// Get signup URL
export const getSignUpUrl = (withNextUrl: boolean = true) => {
    return `${API_URL}/accounts/signup/${
        withNextUrl ? `?next=${window.location.href}` : ""
    }`;
};

// Get login URL
export const getLoginUrl = (withNextUrl: boolean = true) => {
    return `${API_URL}/accounts/login/${
        withNextUrl ? `?next=${window.location.href}` : ""
    }`;
};

// Get logout URL
export const getLogoutUrl = (withNextUrl: boolean = true) => {
    return `${API_URL}/accounts/logout/${
        withNextUrl ? `?next=${window.location.href}` : ""
    }`;
};

// Redirect to signup
export const redirectToSignUp = (trackingData: any = {}) => {
    trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
        ...trackingData,
        event_label: "sign_up"
    });
    window.location.href = getSignUpUrl();
    return;
};

// Redirect to login
export const redirectToLogin = (trackingData: any = {}) => {
    trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
        ...trackingData,
        event_label: "login"
    });
    window.location.href = getLoginUrl();
    return;
};

// Redirect to logout
export const redirectToLogout = (trackingData: any = {}) => {
    trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
        ...trackingData,
        event_label: "logout"
    });
    window.location.href = getLogoutUrl();
    return;
};
