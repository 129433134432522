import React from "react";
import "./App.scss";

import { AppProvider } from "./Context/AppContext";
import Main from "./Main";
import { ConfigProvider } from "antd";

const antdTheme = {
    token: {
        colorPrimary: '#0aa679',
        badgeColor: "#722ed1",
        layoutHeaderBackground: "#f0f2f5",
        btnDefaultGhostBorder: "#E7E7E7",
        btnDefaultGhostColor: "#000",
        menuDarkColor: "#000"
    }
}

const App: React.FC = () => {

    return (
        <ConfigProvider theme={antdTheme}>
            <AppProvider>
                <Main />
            </AppProvider>
        </ConfigProvider>
    );
}

export default App;
