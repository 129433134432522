import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, message, Space, Typography } from 'antd';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StripeBadge from './stripe-badge.svg';
import { API_URL } from '../../const';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import './Wallet.scss';
import { createPaymentIntent, topUpWallet } from '../../servicecs/wallet.service';


interface StripePaymentFormProps {
  cupointsValue: any,
  currency: any,
  currencyValue: any,
  resetReadyToCheckout: any,
  walletId: any,
  refreshUserWallet: (callback: () => void) => void
}


const { Text } = Typography;

const StripePaymentForm: React.FC<StripePaymentFormProps> = ({ cupointsValue, currency, currencyValue, resetReadyToCheckout, refreshUserWallet, walletId }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const dataFetchedRef = useRef(true);


  const onPaymentCancel = () => {
    setIsPaymentFormVisible(false)
    resetReadyToCheckout()
  }

  // Card styles
  const CARD_STYLE: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (!cupointsValue) return;

    if (dataFetchedRef.current) {
      dataFetchedRef.current = false;
      createPaymentIntent(cupointsValue, (clientSecret: string) => {
        setClientSecret(clientSecret);
      })
    }
  }, [cupointsValue]);

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    setDisabled(!event.complete);
    setError(event.error ? event.error.message : "");
  };

  // Confirm card payment
  const confirmPayment = async (ev: any) => {
    const card = elements?.getElement(CardElement);
    if (!stripe || !elements || !card) return;

    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      return;
    }

    const handleAfterTopUpSuccess = () => {
      message.success("Purchase successful.")
      setProcessing(false)
      setSucceeded(true)
      resetReadyToCheckout()
    }

    // Top up wallet
    topUpWallet(walletId, cupointsValue, payload.paymentIntent.id, () => {
      refreshUserWallet(handleAfterTopUpSuccess)
    }, () => {
      setError(`Topup failed!`);
    })
  };


  return (

    <>
      <div className='w-100'>
        <form id="payment-form" >
          <div className='w-100'>
            <div className='w-50'>
              <Text strong>Order details:</Text>
              <br />
              <Text>Purchasing </Text>
              <Text code>{cupointsValue} cupoints</Text>
            </div>
            <div className='w-50'>
              <Text strong> Order Total: </Text>
              <br />
              <Text>Payment of </Text>
              <Text code>{`${currencyValue} ${currency.abbreviation}`}</Text>
            </div>
          </div>
          <br />
          <Text strong>Enter your card information:</Text>
          <br />
          <div id='stripe-container' className='w-100'>
            <div className={`stripe-card-input ${error ? 'stripe-card-error' : ''}`}>
              <CardElement options={CARD_STYLE} onChange={handleChange} />
            </div>
            <div className="card-error" role="alert">
              {error ? error : null}
            </div>

            <div className='payButtonSection'>

              <Button type='primary'
                id='stripe-pay-now'
                className='w-100'
                onClick={confirmPayment}
                loading={processing}
                disabled={!stripe || processing || disabled || succeeded}
              >Pay Now ({`${currencyValue} ${currency.abbreviation}`})</Button>

              <Button type='default' className='w-100'
                onClick={onPaymentCancel}
              >Back</Button>

            </div>
            <div className='powerByStripeContainer'>
              <a target="_blank" rel="noopener noreferrer" href="http://stripe.com">
                <img width={100} src={StripeBadge} id="stripe-badge" />
              </a>
            </div>

          </div>
        </form>
      </div>
    </>

  )
}

export default StripePaymentForm;