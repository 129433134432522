import React, { CSSProperties, useEffect, useRef, useState } from "react";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { useParams } from "react-router";
import { Avatar, Card, Col, List, Row, Space, Tabs, Typography } from "antd";
import { API_URL } from "../../const";
import ExpandableParagraph from "../../Components/ExpandableParagraph/ExpandableParagraph";
import { Course } from "../../Models/Course";
import Loader from "../../Components/Loader/Loader";
import CollectionsList from "../../Components/CollectionsList/CollectionsList";

const { Link, Paragraph, Text, Title } = Typography;
const { TabPane } = Tabs;

const AuthorProfilePage: React.FC = () => {

    const dataFetchedRef = useRef(true);
    const [loading, setLoading] = useState(true);
    const [loadingCourses, setLoadingCourses] = useState(true);
    const [creatorProfile, setCreatorProfile] = useState(null);
    const [certificateCourses, setCertificateCourses] = useState<Course[]>([]);
    const [publicCourses, setPublicCourses] = useState<Course[]>([]);

    let { authorSlug } = useParams();

    const leftColStyle: CSSProperties = {
        top: "18px"
    };


    const fetchCreatorPublicProfile = (slug: string) => {
        setLoading(true);
        fetch(`${API_URL}/api/v1/creators/profile/${slug}/`, {
            method: "GET"
        })
            .then((response) => response.json())
            .then((data: any) => {
                if (data) {
                    setCreatorProfile(data);
                    fetchCreatorPublicCourses(slug);
                }
                setLoading(false);
            });
    };

    const fetchCreatorPublicCourses = (slug: string) => {
        setLoadingCourses(true);
        fetch(`${API_URL}/api/v1/creators/profile/${slug}/certification-courses/`, {
            method: "GET"
        })
            .then((response) => response.json())
            .then((data: Course[]) => {
                if (data) {
                    const tempCertificateCourses: Course[] = [];
                    const tempPublicCourses: Course[] = [];
                    data.forEach((collection: Course) => {
                        if (Boolean(collection.certification)) {
                            tempCertificateCourses.push(collection);
                        } else {
                            tempPublicCourses.push(collection);
                        }
                    });
                    setCertificateCourses(tempCertificateCourses);
                    setPublicCourses(tempPublicCourses);
                }
                setLoadingCourses(false);
            });
    };

    const AuthorProfileSection = ({ creatorProfile }: any) => {
        if (!creatorProfile) return null;

        return (
            <>
                <div className="text-center">
                    <Avatar
                        src={creatorProfile.profilePicture}
                        alt="avatar"
                        style={{ width: "84px", height: "84px" }}
                        size="large"
                        className="creator-profile-picture"
                    />
                    <Title
                        level={4}
                        style={{ marginTop: "1rem", marginBottom: 0 }}>
                        {creatorProfile.firstName} {creatorProfile.lastName}
                    </Title>
                    <Title
                        level={5}
                        type="secondary"
                        style={{ marginTop: 0, marginBottom: 0 }}>
                        {creatorProfile.username}
                    </Title>

                    {creatorProfile.externalWebsite.length && (
                        <Link
                            href={creatorProfile.externalWebsite}
                            target="_blank">
                            {creatorProfile.externalWebsite}
                        </Link>
                    )}
                    <br />
                    <Text type="secondary">Affiliations</Text>
                    <br />
                    <Space align="start">
                        <Text>
                            {creatorProfile.universities
                                .map((university: any) => university.name)
                                .join(", ")}
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <ExpandableParagraph style={{ padding: "0 1rem" }} content={creatorProfile.bio} lines={3} />
                </div>
            </>
        );
    };

    const AuthorColectionTabSection = () => {
        return (
            <Tabs defaultActiveKey="1" style={{ display: "flex" }} tabBarStyle={{
                marginLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
            }}>
                <TabPane tab={
                    <div style={{ width: "100%", textAlign: "center" }}>Certificate Courses</div>
                } key="1">
                    <CollectionsList
                        collections={certificateCourses}
                        redirectToCertificatePage={true}
                    />
                </TabPane>
                <TabPane tab={
                    <div style={{ width: "100%", textAlign: "center" }}>Other Courses</div>
                } key="2">
                    <CollectionsList
                        collections={publicCourses}
                    />
                </TabPane>
            </Tabs>
        );
    };

    useEffect(() => {
        if (authorSlug && dataFetchedRef.current) {
            dataFetchedRef.current = false;
            fetchCreatorPublicProfile(authorSlug);
        }
    }, [authorSlug]);

    return (
        <>
            <MobileHeader title={"Author"} />
            <div className={`page`}>
                {(loading || loadingCourses) ? <Loader /> : (
                    <>
                        <Row gutter={[16, 0]}>
                            <Col
                                xs={24}
                                sm={24}
                                md={8}
                                lg={8}
                                xl={8}
                                style={leftColStyle}>
                                <AuthorProfileSection
                                    creatorProfile={creatorProfile}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                            <Col
                                xs={24}
                                sm={24}
                                md={8}
                                lg={8}
                                xl={8}
                                style={leftColStyle}>
                                <AuthorColectionTabSection />
                            </Col>
                        </Row>
                    </>
                )}

            </div>
        </>
    );
}

export default AuthorProfilePage;