import { Space, Typography } from "antd";
import { FC } from "react";
import RechargeButton from "../Wallet/RechargeButton";

const { Title, Text } = Typography;

interface BalanceInsufficient {
    netPrice: number,
    currentBalance: number
}

const BalanceInsufficient: FC<BalanceInsufficient> = (props: BalanceInsufficient) => {
    return (
        <div style={{ paddingTop: 25 }}>
            <Title level={3}>Insufficient Balance</Title>
            <Space size={"large"}>
                <Text>Looks like you are short</Text>
                <Text>
                    {props.netPrice -
                        props.currentBalance}{" "}
                    cupoints
                </Text>
            </Space>
            <br />
            <Space size={"large"}>
                <Text type="secondary">Current Wallet Balance:</Text>
                <Text type="secondary">
                    {props.currentBalance} cupoints
                </Text>
            </Space>
            <br />
            <br />
            <Space direction="vertical" style={{ width: "100%" }}>
                <Text>
                    Click the button below to refill your balance.
                </Text>
                <RechargeButton />
            </Space>
        </div>
    )
}

export default BalanceInsufficient;