import { API_URL } from "../const";
import { CertificateInfo } from "../Models/Certificate";
import { Course } from "../Models/Course";
import { getNewAccessToken } from "./user.service";

export const fetchCollection = (
    collectionId: number,
    authenticated: boolean,
    onSuccess: (data: any) => void = (data: any) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    fetch(`${API_URL}/api/v1/collections/${collectionId}/`, {
        method: "GET",
        headers: authenticated
            ? {
                  Authorization: "Bearer " + localStorage.getItem("access"),
                  "Content-Type": "application/json"
              }
            : { "Content-Type": "application/json" }
    })
        .then((response) => response.json())
        .then((data: Course) => {
            onSuccess(data);
        })
        .catch((error: any) => {
            onError(error);
        });
};

export const fetchModuleAccess = (
    collectionId: number,
    onSuccess: (data: any) => void = (data: any) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    fetch(`${API_URL}/api/v1/collections/${collectionId}/module-access/`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        }
    })
        .then((response) => response.json())
        .then((data) => {
            onSuccess(data);
        })
        .catch((error: any) => {
            onError(error);
        });
};

export const purchaseCollection = (
    collection: Course,
    setLoadingStatus: (loading: boolean) => void,
    certificate: boolean = false,
    onSuccess: (data: any) => void = (data: any) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    const collectionId = collection.id;

    // TODO: Bind coupons when purchase collection - Mobile requirement pending
    // const activeCoupon = getState().userReducer.activeCoupon;
    // const couponCode =
    //     activeCoupon &&
    //     activeCoupon.discountedCollections.includes(collectionId)
    //         ? activeCoupon.code
    //         : "";

    setLoadingStatus(true);
    let postData: any = { couponCode: "" };
    if (certificate) {
        postData = { ...postData, certificate: certificate };
    }
    if (collection.promotion) {
        postData = {
            ...postData,
            promotionCode: collection.promotion.code
        };
    }
    return fetch(API_URL + `/api/v1/collections/${collectionId}/purchase/`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    })
        .then((response) => {
            if (response.ok || response.status === 400) {
                return response.json();
            } else {
                onError(response.status);
            }
        })
        .then((data) => {
            onSuccess(data);
            setLoadingStatus(false);
        })
        .catch((error) => {
            if (parseInt(error.message) === 401) {
                getNewAccessToken(() =>
                    purchaseCollection(
                        collection,
                        setLoadingStatus,
                        certificate,
                        onSuccess,
                        onError
                    )
                );
            } else {
                console.log(error);
                setLoadingStatus(false);
            }
        });
};

export const fetchCollectionCertificationDetails = (
    collectionId: number,
    onSuccess: (data: CertificateInfo) => void = (data: CertificateInfo) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    fetch(
        `${API_URL}/api/v1/collections/${collectionId}/certification-details/`,
        {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        }
    )
        .then((response) => response.json())
        .then((data: CertificateInfo) => {
            onSuccess(data);
        })
        .catch((error: any) => {
            onError(error);
        });
};

export const evaluateCollectionCertification = (
    collectionId: number,
    onSuccess: (data: CertificateInfo) => void = (data: CertificateInfo) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    fetch(API_URL + `/api/v1/collections/${collectionId}/evaluate/`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({})
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                onError(response.status);
            }
        })
        .then((data) => {
            setTimeout(() => {
                onSuccess(data);
            }, 2000);
        })
        .catch((error: any) => {
            if (parseInt(error.message) === 401) {
                getNewAccessToken(() =>
                    evaluateCollectionCertification(
                        collectionId,
                        onSuccess,
                        onError
                    )
                );
            } else {
                onError(error);
            }
        });
};

export const requestCollectionCertification = (
    collectionId: number,
    onSuccess: (data: CertificateInfo) => void = (data: CertificateInfo) => {},
    onError: (error: any) => void = (error: any) => {}
) => {
    fetch(
        API_URL + `/api/v1/collections/${collectionId}/request-certificate/`,
        {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                onError(response.status);
            }
        })
        .then((data) => {
            setTimeout(() => {
                onSuccess(data);
            }, 2000);
        })
        .catch((error) => {
            if (parseInt(error.message) === 401) {
                getNewAccessToken(() =>
                    requestCollectionCertification(
                        collectionId,
                        onSuccess,
                        onError
                    )
                );
            } else {
                onError(error);
            }
        });
};
