export enum TRACKING_EVENTS {
    NONE = "",
    PAGE_LOAD = "page_load",
    PAGE_VIEW = "page_view",
    REDIRECT = "redirect",
    BUTTON_CLICK = "button_click"
}

// ad_impression           - sees an advertisement, for apps only
// earn_virtual_currency   - earns virtual currency (coins, gems, tokens, etc.)
// generate_lead           - submits a form or a request for information
// join_group              - joins a group
// login                   - logs in
// purchase                - completes a purchase
// refund                  - receives a refund
// search                  - searches your website or app
// select_content          - selects content on your website or app
// share                   - shares content from your website or app
// sign_up                 - signs up for an account on your website or app
// spend_virtual_currency  - spends virtual currency (coins, gems, tokens, etc.)
// tutorial_begin          - begins a tutorial during an on-boarding process
// tutorial_complete       - completes a tutorial during an on-boarding process
// add_payment_info        - submits their payment information during checkout
// add_shipping_info       - submits their shipping information during checkout
// add_to_cart             - adds items to their shopping cart
// add_to_wishlist         - adds items to their wishlist
// begin_checkout          - begins checkout
// purchase                - completes a purchase
// refund                  - receives a refund
// remove_from_cart        - removes items from their shopping cart
// select_item             - selects an item from a list of items or offerings
// select_promotion        - selects a promotion
// view_cart               - views their shopping cart
// view_item               - views an item
// view_item_list          - views a list of items or offerings
// view_promotion          - views a promotion on your website or app
