import { IS_PROD } from "../const";
import AmplitudeTracking from "./AmplitudeTracking";
import GoogleTracking from "./GoogleTracking";
import { TRACKING_EVENTS } from "./TrackingEvents";

class Tracking {
    trackers: any;
    constructor() {
        this.trackers = {
            google: new GoogleTracking(),
            amplitude: new AmplitudeTracking()
        };
    }

    // Init all tracking services
    init() {
        if (!IS_PROD) return;

        Object.values(this.trackers).forEach((tracker: any) => {
            tracker.initialize();
        });
    }

    // Common method for tracking events
    dispatchTrackingEvent(eventName: TRACKING_EVENTS, eventData: any = {}) {
        Object.values(this.trackers).forEach((tracker: any) => {
            if (tracker.isEnabled) {
                tracker.trackEvent(eventName, eventData);
            }
        });
    }

    // Format Data before send to event listner
    formatParsedData(data: any = {}, user?: any) {
        // set URL to tracking data
        data = { ...data, url: window.location.href };

        // Bind logged user details
        if (Boolean(user)) {
            data.user = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                username: user.username
            };
        }

        // Bind Video information
        if (Boolean(data.video)) {
            data.video = {
                id: data.video.id,
                embedId: data.video.embedId,
                title: data.video.title,
                videoType: data.video.videoType
            };
        }

        // Bind Collection information
        if (Boolean(data.collection)) {
            data.collection = {
                id: data.collection.id,
                name: data.collection.name
            };
        }

        // Bind Module information
        if (Boolean(data.module)) {
            data.module = {
                id: data.module.id,
                name: data.module.name
            };
        }

        return data;
    }

    // Common method for tracking page views
    trackPageView(pagePath: string, data: any = {}, user: any = undefined) {
        const parsedData = this.formatParsedData(data, user);
        this.dispatchTrackingEvent(TRACKING_EVENTS.PAGE_VIEW, {
            ...parsedData,
            page_path: pagePath
        });
    }

    // Track Events
    trackEvent(event: TRACKING_EVENTS, data: any = {}, user: any = undefined) {
        if (event === TRACKING_EVENTS.BUTTON_CLICK) {
            data.event_category = "Button";
        }
        const parsedData = this.formatParsedData(data, user);
        this.dispatchTrackingEvent(event, parsedData);
    }
}

const trackingInstance = new Tracking();
export default trackingInstance;
