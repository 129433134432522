import { Typography } from "antd";
import { CSSProperties, useState } from "react";

const { Paragraph } = Typography;
interface ExpandableParagraphProps {
    content: any,
    lines: number,
    style?: CSSProperties
}

const ExpandableParagraph = ({ content, lines = 3, style }: ExpandableParagraphProps) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Paragraph style={style || {}}
                ellipsis={expanded ? {
                    expandable: false,
                    symbol: (<span onClick={toggleExpanded} style={{ marginLeft: 5 }}>Show less</span>)
                } : {
                    rows: lines,
                    expandable: true,
                    symbol: (<span onClick={toggleExpanded} style={{ marginLeft: 5 }}>Show more</span>)
                }}
            >
                {content}
            </Paragraph>
        </div>
    );
}

export default ExpandableParagraph;