import amplitude, { AmplitudeClient } from "amplitude-js";
import { IS_PROD } from "../const";
import { TRACKING_EVENTS } from "./TrackingEvents";

class AmplitudeTracking {
    AMPLITUDE_API_KEY = "7e31a4cb8779fc0c4c4b34184680c655";
    amplitudeClient = null;
    isEnabled = false;
    constructor() {}

    getInstance = (): AmplitudeClient => {
        return amplitude.getInstance();
    };

    // Initialize amplitude tracking
    initialize() {
        const instance = this.getInstance();

        instance.setVersionName("Web");
        instance.init(
            this.AMPLITUDE_API_KEY,
            undefined,
            { includeReferrer: true, includeUtm: true, includeGclid: true },
            (client: any) => {
                this.amplitudeClient = client;
                this.isEnabled = true;

                // Track Page Load Event
                this.trackEvent(TRACKING_EVENTS.PAGE_LOAD);
            }
        );
    }

    trackEvent(eventName: TRACKING_EVENTS, eventData = {}) {
        if (!this.isEnabled || !this.amplitudeClient) return;
        const instance = this.getInstance();

        return instance.logEvent(eventName, eventData);
    }
}

export default AmplitudeTracking;
