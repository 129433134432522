import { useEffect, useRef, useState } from "react";
import "./IntroPreviewer.scss";
import YouTube from "react-youtube";
import Player from "@vimeo/player";
import { Video } from "../../Models/Video";
import { Course } from "../../Models/Course";
import { Button } from "antd";
import { FaPlayCircle, FaTimesCircle } from "react-icons/fa";

interface IntroPreviewerProps {
    collection: Course,
    thumbnail: React.ReactNode,
    videoType: "youtube" | "vimeo",
    videoUrl: string,
}

interface PreviewerProps {
    afterPreviewEnd: () => void
}

const YT_PLAYER_OPTIONS = {
    width: "100%",
    height: "100%",
    playerVars: {
        autoplay: 1,
        rel: 0
    }
};

const asyncIntervals: any[] = [];


const clearAsyncInterval = (intervalIndex: number) => {
    if (asyncIntervals[intervalIndex]) {
        asyncIntervals[intervalIndex] = false;
    }
};

const IntroPreviewer: React.FC<IntroPreviewerProps> = ({
    collection,
    thumbnail,
    videoType,
    videoUrl
}) => {
    const containerRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [video, setVideo] = useState<Video | null>(null);

    const YoutubePreviewer: React.FC<PreviewerProps> = ({ afterPreviewEnd }) => {
        const [youtubePlayer, setYoutubePlayer] = useState<any>(null);

        const destroyPlayer = () => {
            asyncIntervals.forEach((e, i) => {
                clearAsyncInterval(i);
            });
            youtubePlayer?.destroy();
            setYoutubePlayer(null);
        };

        const endVideo = () => {
            youtubePlayer?.pauseVideo();
            destroyPlayer();
            afterPreviewEnd();
        };

        const onReady = (e: any) => {
            setYoutubePlayer(e.target);
        };

        useEffect(() => {
            if (youtubePlayer) {
                return () => {
                    // destroyPlayer();
                };
            }
        }, [youtubePlayer]);

        return (
            <>
                {video !== null && (
                    <YouTube
                        videoId={video.embedId}
                        opts={YT_PLAYER_OPTIONS}
                        onReady={onReady}
                        onEnd={endVideo}
                    />
                )}
            </>

        );
    };

    const VimeoPreviewer: React.FC<PreviewerProps> = ({ afterPreviewEnd }) => {
        const vimeoContainerRef = useRef(null);
        const [vimeoPlayer, setVimeoPlayer] = useState<Player | null>(null);

        const destroyPlayer = () => {
            asyncIntervals.forEach((e, i) => {
                clearAsyncInterval(i);
            });
            vimeoPlayer?.off("play");
            setVimeoPlayer(null);
        };

        const endVideo = () => {
            if (!vimeoPlayer) return;

            vimeoPlayer.pause();
            destroyPlayer();
            afterPreviewEnd();
        };

        const onVideoEnded = () => {
            endVideo();
        };

        const handleVimeoOnPlay = () => { };

        useEffect(() => {
            if (vimeoContainerRef?.current && !vimeoPlayer && video) {
                const player = new Player(vimeoContainerRef?.current, {
                    id: Number(video.embedId),
                    autoplay: true,
                    // width: "100%",
                    // height: "100%",
                    // responsive: true
                } as any);
                setVimeoPlayer(player);
            }
        }, [vimeoContainerRef]);

        useEffect(() => {
            if (vimeoPlayer) {
                vimeoPlayer.on("play", handleVimeoOnPlay);
                vimeoPlayer.on("ended", onVideoEnded);
                vimeoPlayer.on("error", () => {
                    destroyPlayer();
                });
                return () => {
                    destroyPlayer();
                };
            }
        }, [vimeoPlayer]);

        return (
            <>
                <div className="vimeo-video-wrapper">
                    <div ref={vimeoContainerRef}></div>
                </div>
            </>
        );
    };

    const processVideo = (video: Video | null) => {
        if (video === null) {
            setVideo(null);
        } else {
            setVideo(video);
        }
    };

    const getEmbedIdFromUrl = (url: string) => {
        let video_id_regExp =
            /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
            match = url.match(video_id_regExp);

        if (match && match[7]) {
            return match[7];
        }
        return "";
    };

    useEffect(() => {
        if (playing && videoType && videoUrl) {
            const embedId = getEmbedIdFromUrl(videoUrl);
            processVideo(
                embedId
                    ? {
                        videoType: videoType,
                        embedId: embedId
                    } as Video
                    : null
            );
        } else {
            processVideo(null);
        }
    }, [playing, videoType, videoUrl]);

    return (
        <>
            <div
                ref={containerRef}
                className="course-previewer">
                <div
                    style={{ opacity: video && playing ? 0 : 1 }}
                    className={video ? "course-previewer-thumbnail" : undefined}>
                    {thumbnail}
                </div>
                <div
                    className="course-previewer-video-previewer"
                    style={{ opacity: video && playing ? 1 : 0 }}>
                    {video?.videoType === "youtube" && (
                        <YoutubePreviewer
                            afterPreviewEnd={() => setPlaying(false)}
                        />
                    )}
                    {video?.videoType === "vimeo" && (
                        <VimeoPreviewer
                            afterPreviewEnd={() => setPlaying(false)}
                        />
                    )}
                </div>
                {Boolean(videoType) && Boolean(videoUrl) && (
                    <Button className="play-intro-btn" color="dark" size="small" icon={playing ? <FaTimesCircle /> : <FaPlayCircle />} onClick={() => setPlaying(!playing)}>
                        {playing ? 'Close' : 'Play Intro'}
                    </Button>
                )}
            </div>
        </>
    );
};

export default IntroPreviewer;
