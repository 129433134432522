import { CheckCircleOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd"
import { PURCHASE_TYPES } from "../../Models/Purchase";
import { FC, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import { purchaseCollection } from "../../servicecs/collection.service";
import { Course, Module } from "../../Models/Course";
import { Certification } from "../../Models/Certificate";

const { Title, Text } = Typography;

interface ContinuePurchaseProps {
    type: PURCHASE_TYPES,
    purchaseAmount: number,

    course?: Course,
    module?: Module,
    certification?: Certification,

    onSuccess: (data: any) => void,
    onError: (error: any) => void
}

const ContinuePurchase: FC<ContinuePurchaseProps> = (props: ContinuePurchaseProps) => {
    const { user } = useAppContext();
    const text = props.type === PURCHASE_TYPES.COURSE ? 'course' : (props.type === PURCHASE_TYPES.CERTIFICATE ? 'certificate' : 'module');

    const [purchaseLoading, setPurchaseLoading] = useState(false);

    const handleOnPurchaseClick = () => {
        switch (props.type) {
            case PURCHASE_TYPES.COURSE:
                purchaseCollection(props.course!, setPurchaseLoading, false, props.onSuccess, props.onError)
                break;
            case PURCHASE_TYPES.CERTIFICATE:
                purchaseCollection(props.course!, setPurchaseLoading, true, props.onSuccess, props.onError)
                break;
            default:
                break;
        }
    }

    return (
        <div style={{ paddingTop: 25 }}>
            <Space>
                <Title level={5}>
                    <CheckCircleOutlined />
                </Title>
                <Title level={5}>Your balance is  sufficient to purchase this {text}</Title>
            </Space>
            <Space size={"large"}>
                <Text type="secondary">Current Wallet Balance:</Text>
                <Text type="secondary">
                    {user?.primaryWallet?.cupointsBalance} cupoints
                </Text>
            </Space>
            <br />
            <br />
            <Button
                type="primary"
                className="w-100"
                loading={purchaseLoading}
                icon={<ThunderboltOutlined />}
                onClick={handleOnPurchaseClick}>
                Purchase {text} @ {props.purchaseAmount}
            </Button>
        </div>
    )
}

export default ContinuePurchase