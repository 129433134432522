import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'antd';

interface MobileConfirmationProps {
    isOpen: boolean;
    title?: string;
    content?: ReactNode;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    confirmButtonProps?: React.ComponentProps<typeof Button>;
    cancelButtonProps?: React.ComponentProps<typeof Button>;
}

const MobileConfirmation: FC<MobileConfirmationProps> = ({
    isOpen,
    title = 'Confirmation',
    content,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
    confirmButtonProps,
    cancelButtonProps
}) => {
    return (
        <Modal
            open={isOpen}
            title={title}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel} {...cancelButtonProps}>
                    {cancelButtonText}
                </Button>,
                <Button key="confirm" type="primary" onClick={onConfirm} {...confirmButtonProps}>
                    {confirmButtonText}
                </Button>,
            ]}
            centered
        >
            {content}
        </Modal>
    );
};

export default MobileConfirmation;
