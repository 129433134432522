import { Typography } from "antd";
import { FC } from "react";
import { redirectToLogin, redirectToSignUp } from "../../utils";

const { Title } = Typography;

interface AuthenticationRequiredProps {
    trackingData?: any
}

const AuthenticationRequired: FC<AuthenticationRequiredProps> = ({
    trackingData
}) => {
    return (
        <>
            <div className={"login-wrapper"}>
                <Title type="secondary" level={5}>You are not logged in. Please sign in to continue.</Title>
                <p className="text-center">
                    <button
                        className="signin-btn default-btn btn-block"
                        onClick={() => redirectToLogin(trackingData)}>
                        Sign In
                    </button>
                    <button
                        className="signup-btn light-btn btn-block"
                        onClick={() => redirectToSignUp(trackingData)}>
                        Sign Up
                    </button>
                </p>
            </div>
        </>
    )
}

export default AuthenticationRequired;