import { FC, PropsWithChildren } from "react";

interface CupointsProps {
    points: number,
    secondaryWithinBrackets?: boolean
}

const Cupoints: FC<PropsWithChildren<CupointsProps>> = ({ children, points, secondaryWithinBrackets }: PropsWithChildren<CupointsProps>) => {

    const convertToUsd = (p: number) => {
        const num = p / 100;
        return Number.isInteger(num) ? num : num.toFixed(2);
    };

    return (
        <span>
            {children}
            {points === 0 ? (
                <span className="cupoints-container">
                    <>Free</>
                </span>
            ) : (
                <span className="cupoints-container">
                    <span className="dollar-amount">
                        US ${convertToUsd(points)}{" "}
                    </span>
                    <span className="cupoints-amount">
                        {secondaryWithinBrackets
                            ? `(${points} cupoint${points > 1 ? "s" : ""})`
                            : `${points} cupoint${points > 1 ? "s" : ""}`}
                    </span>
                </span>
            )}
        </span>
    )
}

export default Cupoints;