import { FC, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import { CERTIFICATE_STATUS, CertificateInfo } from "../../Models/Certificate";
import Cupoints from "../../Components/Cupoints/Cupoints";
import { Course } from "../../Models/Course";
import { Alert, Button, Card, message, Modal, Popconfirm, Space } from "antd";
import PurchaseModule from "../../Components/PurchaseModule/PurchaseModule";
import { PURCHASE_TYPES } from "../../Models/Purchase";
import { ClockCircleOutlined, FrownOutlined, InfoCircleOutlined, ThunderboltOutlined, WarningOutlined } from "@ant-design/icons";
import MobileModal from "../../Components/MobileModal/MobileModal";
import AuthenticationRequired from "../../Components/AuthenticationRequired/AuthenticationRequired";
import trackingInstance from "../../Tracking/Tracking";
import { TRACKING_EVENTS } from "../../Tracking/TrackingEvents";
import MobileConfirmation from "../../Components/MobileConfirmation/MobileConfirmation";
import { evaluateCollectionCertification, requestCollectionCertification } from "../../servicecs/collection.service";

interface CertificationActionButtons {
    collection: Course,
    certificateInfo: CertificateInfo,
    collectionNetPrice: number,
    onRefreshData: () => void
}
const CertificationActionButtons: FC<CertificationActionButtons> = ({
    certificateInfo,
    collection,
    collectionNetPrice,
    onRefreshData
}: CertificationActionButtons) => {
    const { authenticated, user } = useAppContext();
    if (!collection?.certification) return null;

    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [purchaseLoading, setPurchaseLoading] = useState<boolean>(false);
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

    const onStartTrialButtonClick = () => {
        setShowLoginModal(true);
        trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
            collection,
            event_label: "start_trial_click"
        });
    }

    // Free Trial Related
    const [freeTrialModalOpen, setFreeTrialModalOpen] = useState<boolean>(false);
    const onFreeTrialBtnClicked = (e?: React.MouseEvent<HTMLElement>) => {
        e?.stopPropagation();
        setFreeTrialModalOpen(true);
        trackingInstance.trackEvent(
            TRACKING_EVENTS.BUTTON_CLICK,
            {
                collection,
                event_label: "start_trial_click"
            }
        );
    }
    const confirmEvaluation = () => {
        setRequestInProgress(true);
        trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
            collection,
            event_label: "start_trial_accepted"
        });
        evaluateCollectionCertification(collection.id, (certificateInfo: Partial<CertificateInfo>) => {
            setFreeTrialModalOpen(false);
            onRefreshData();
            message.success(
                "Evaluation success!"
            );
            setRequestInProgress(false);
        }, (error) => {
            message.error(
                "Evaluation failed!"
            );
            setRequestInProgress(false);
        });
    };

    // Start Certificateion Related
    const [startCertificateModalOpen, setStartCertificateModalOpen] = useState<boolean>(false);
    const startCertificateBtnClicked = (e?: React.MouseEvent<HTMLElement>) => {
        setStartCertificateModalOpen(true);
        trackingInstance.trackEvent(
            TRACKING_EVENTS.BUTTON_CLICK,
            {
                collection,
                event_label:
                    "start_certificate_click"
            }
        );
    }
    const handleOnPurchaseClick = () => {
        if (!user) return;
        trackingInstance.trackEvent(TRACKING_EVENTS.BUTTON_CLICK, {
            collection,
            event_label: "start_certificate_accepted"
        });
        onRefreshData();
        setStartCertificateModalOpen(false);
    };

    // Request certificate related
    const [requestCertificateModalOpen, setRequestCertificateModalOpen] = useState<boolean>(false);
    const requestCertificateBtnClicked = (e?: React.MouseEvent<HTMLElement>) => {
        setRequestCertificateModalOpen(true);
    }
    const requestCertificate = () => {
        setRequestInProgress(true);
        requestCollectionCertification(collection.id, (data: any) => {
            setRequestCertificateModalOpen(false);
            onRefreshData();
            message.success(
                "You have successfully requested certificate!"
            );
            setRequestInProgress(false);
        }, (error) => {
            message.error(
                "Failed to request certificate!"
            );
            setRequestInProgress(false);
        })
    };

    const CupointsSection = () => {
        return (
            <>
                <div className="cupoint-section">
                    <div className="cu">
                        <Cupoints
                            points={collectionNetPrice}
                            secondaryWithinBrackets={false}></Cupoints>
                    </div>
                    {collection.promotion && (
                        <div className="ml-auto">
                            <div className="promo-apply-label">
                                {parseFloat(
                                    collection.promotion
                                        .promoDiscount
                                ) * 100}
                                {"% "}
                                PROMOTION APPLIED
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    // Certificate not purchased section
    const CertificateNotPurchasedSection = () => {
        return (
            <>
                {/* Show Collection Price */}
                <CupointsSection />

                {/* If evaluation not exist, show Free Trial button */}
                {certificateInfo?.evaluationShow && (
                    <Space direction="vertical" style={{ width: "100%" }}>

                        <Button
                            block
                            onClick={onFreeTrialBtnClicked}
                            loading={certificateInfo?.evaluationProgress}>
                            Free Trial
                        </Button>
                        {freeTrialModalOpen && (
                            <MobileConfirmation
                                isOpen={freeTrialModalOpen}
                                title="Are you sure to evaluate this course?"
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                onConfirm={confirmEvaluation}
                                confirmButtonProps={{
                                    loading: requestInProgress
                                }}
                                onCancel={(e?: React.MouseEvent<HTMLElement>) => {
                                    e?.stopPropagation();
                                    setFreeTrialModalOpen(false);
                                    trackingInstance.trackEvent(
                                        TRACKING_EVENTS.BUTTON_CLICK,
                                        {
                                            collection,
                                            event_label: "start_trial_rejected"
                                        }
                                    );
                                }} />
                        )}
                    </Space>
                )}

                {/* If evaluation not exist, show Trial expiration information */}
                {certificateInfo.evaluationExists && (
                    <Alert style={{ padding: "0.5rem", fontSize: "12px" }}
                        message={
                            certificateInfo.evaluationExpired
                                ? "Your free trial has expired!"
                                : "You are in trial period!"
                        }
                        description={
                            certificateInfo.evaluationExpired ? (
                                <>
                                    To continue to use this certificate, you
                                    need to purchase full certificate.
                                </>
                            ) : (
                                <>
                                    Your trial period ends in{" "}
                                    {certificateInfo.evaluationEndsIn}. Consider
                                    purchase to keep using certificate long
                                    time.
                                </>
                            )
                        }
                        icon={
                            certificateInfo.evaluationExpired ? (
                                <FrownOutlined />
                            ) : (
                                <WarningOutlined />
                            )
                        }
                        type={
                            certificateInfo.evaluationExpired
                                ? "error"
                                : "warning"
                        }
                        showIcon
                    />
                )}
            </>
        );
    };

    const CertificatePurchasedAndNotExpired = () => {
        return (
            <div className="certificate-purchased-not-expired-section">
                {/* When certificate not requested */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.NOT_REQUESTED && (
                        <>
                            <Alert style={{ padding: "0.5rem", fontSize: "12px", marginBottom: "4px" }}
                                message={"Learning Period"}
                                description={
                                    <>
                                        Now you are elligible for request
                                        certificate. You have{" "}
                                        <b>{certificateInfo.certificateEndsIn}</b>{" "}
                                        to complete course and meet our minimum
                                        performance level. And then you can request
                                        certificate.
                                    </>
                                }
                                type="info"
                                showIcon
                                icon={<InfoCircleOutlined />}
                            />

                            <Button
                                block
                                onClick={requestCertificateBtnClicked}>
                                Request Certificate
                            </Button>
                            <MobileConfirmation
                                isOpen={requestCertificateModalOpen}
                                title="Are you sure you want to request certification?"
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                onConfirm={requestCertificate}
                                confirmButtonProps={{
                                    loading: requestInProgress
                                }}
                                onCancel={(e?: React.MouseEvent<HTMLElement>) => {
                                    e?.stopPropagation();
                                    setRequestCertificateModalOpen(false);
                                }} />

                        </>
                    )}

                {/* When certificate requested, but not approved yet */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.REQUESTED && (
                        <Alert style={{ padding: "0.5rem", fontSize: "12px", marginBottom: "4px" }}
                            message={"Evaluation In-progress"}
                            description={
                                <>
                                    Thank you for submitting your certification
                                    request. We are currently evaluating your
                                    performance as part of the certification
                                    process. We appreciate your patience during this
                                    review.
                                </>
                            }
                            type="info"
                            showIcon
                            icon={<ClockCircleOutlined />}
                        />
                    )}

                {/* Certificate Rejected */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.REJECTED && (
                        <>
                            <Alert style={{ padding: "0.5rem", fontSize: "12px", marginBottom: "4px" }}
                                message={"Certification Rejected"}
                                description={
                                    <>
                                        You did not meet the minimum performance
                                        level required. Please complete the
                                        necessary learning and reapply for the
                                        certification.
                                    </>
                                }
                                type="error"
                                showIcon
                                icon={<FrownOutlined />}
                            />
                            <Button
                                block
                                onClick={requestCertificateBtnClicked}>
                                Re-request Certificate
                            </Button>
                            <MobileConfirmation
                                isOpen={requestCertificateModalOpen}
                                title="Are you sure you want to re-request certification?"
                                confirmButtonText="Yes"
                                cancelButtonText="No"
                                onConfirm={requestCertificate}
                                confirmButtonProps={{
                                    loading: requestInProgress
                                }}
                                onCancel={(e?: React.MouseEvent<HTMLElement>) => {
                                    e?.stopPropagation();
                                    setRequestCertificateModalOpen(false);
                                }} />
                        </>
                    )}
            </div>
        );
    };

    // Certificate Purchased
    const CertificatePurchased = () => {
        return (
            <>
                {certificateInfo.certificateExpired ? (
                    <Alert style={{ padding: "0.5rem", fontSize: "12px" }}
                        message={"Certificate Expired"}
                        description={<>Your certification has expired.</>}
                        type="error"
                        showIcon
                        icon={<FrownOutlined />}
                    />
                ) : (
                    <CertificatePurchasedAndNotExpired />
                )}
            </>
        );
    };

    return (
        <>
            {authenticated ? (
                <>
                    {/* If certificate not purchased yet: START */}
                    {!certificateInfo.certificatePurchased && (
                        <CertificateNotPurchasedSection />
                    )}

                    <Space
                        direction="vertical"
                        style={{ width: "100%" }}>
                        {!certificateInfo.certificatePurchased ? (
                            //  If certificate not purchased, show purchase button
                            <>
                                <Button
                                    block
                                    type="primary"
                                    loading={purchaseLoading}
                                    icon={<ThunderboltOutlined />}
                                    onClick={startCertificateBtnClicked}>
                                    Start Certificate
                                </Button>
                                {startCertificateModalOpen && (
                                    <PurchaseModule
                                        type={PURCHASE_TYPES.CERTIFICATE}
                                        netPrice={collectionNetPrice}
                                        course={collection}
                                        onClose={() => {
                                            setStartCertificateModalOpen(false);
                                            trackingInstance.trackEvent(
                                                TRACKING_EVENTS.BUTTON_CLICK,
                                                {
                                                    collection,
                                                    event_label: "start_certificate_rejected"
                                                }
                                            );
                                        }}
                                        onSuccess={handleOnPurchaseClick}>
                                        <Card>
                                            <Cupoints points={collectionNetPrice} secondaryWithinBrackets={true}>
                                                Purchase all {collectionNetPrice > 0 ? "@" : null}
                                            </Cupoints>
                                        </Card>
                                    </PurchaseModule>
                                )}
                            </>
                        ) : (
                            // if certificate purchased, show details
                            <CertificatePurchased />
                        )}
                    </Space>
                </>
            ) : (
                <>
                    <Button type="primary" size="middle" className="text-center w-100" icon={<ThunderboltOutlined />} onClick={onStartTrialButtonClick}>
                        START A 7-DAY FREE TRIAL
                    </Button>

                    {showLoginModal && (
                        <MobileModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
                            <AuthenticationRequired trackingData={{
                                collection
                            }} />
                        </MobileModal>
                    )}
                </>
            )}
        </>
    );
};

export default CertificationActionButtons;