import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Logo/Logo";
import { useAppContext } from "../../Context/AppContext";
import { useEffect, useState } from "react";
import { Button, Drawer, Input, Layout, Menu, MenuProps } from "antd";
import { ArrowLeftOutlined, BugOutlined, CloseOutlined, HomeOutlined, LogoutOutlined, MenuOutlined, QuestionCircleOutlined, SearchOutlined, UnorderedListOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";
import "./MobileHeader.scss";
import { getLoginUrl, getLogoutUrl, getSignUpUrl, redirectToLogout } from "../../utils";

interface MobileHeaderProps {
    searchBar?: boolean,
    searchBarText?: string,
    showBackButton?: boolean,
    title?: string,
    onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type MenuItemType = Required<MenuProps>['items'][any];

const { Header } = Layout;
const headerPaths: any = {
    '/': "1",
    '/collections/': "2",
    '/my-collections/': "3",
    '/wallet/': "4",
    '/faq/': "5",
    '/bugreport/': "6",
}

const MobileHeader = (props: MobileHeaderProps) => {

    const { searchBar, onSearch, searchBarText, showBackButton = true, title } =
        props;

    const { authenticated, navbarOpen, setNavbarOpen, searchBarOpen, setSearchBarOpen, user } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItemType[]>([]);
    const [selectedKey, setSelectedKey] = useState<string>("1");

    const toggleSearch = () => {
        setSearchBarOpen(!searchBarOpen);
    };

    const toggleMenu = () => {
        setNavbarOpen(!navbarOpen);
    };

    const closeSideBar = () => {
        setNavbarOpen(false);
    };

    const logout = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("userId");
        redirectToLogout();
        closeSideBar();
    };


    useEffect(() => {
        if (location.pathname in headerPaths) {
            setSelectedKey(headerPaths[location.pathname])
        } else {
            setSelectedKey("1");
        }
    }, [location.pathname]);

    useEffect(() => {
        const menuItems = [
            {
                key: "1",
                icon: <HomeOutlined />,
                label: (<Link to="/" onClick={closeSideBar}>Home</Link>),
                visible: true
            }, {
                key: "2",
                icon: <UnorderedListOutlined />,
                label: (<Link to="/collections/" onClick={closeSideBar}>Premium Courses</Link>),
                visible: true
            }, {
                key: "3",
                icon: <UnorderedListOutlined />,
                label: (<Link to="/my-collections/" onClick={closeSideBar}>My Courses</Link>),
                visible: authenticated
            }, {
                key: "4",
                icon: <WalletOutlined />,
                label: (<Link to="/wallet/" onClick={closeSideBar}>
                    Account / Wallet (<small>{user?.primaryWallet?.cupointsBalance} cupoints</small>)
                </Link>),
                visible: authenticated
            }, {
                key: "5",
                icon: <QuestionCircleOutlined />,
                label: (<Link to="/faq/" onClick={closeSideBar}>FAQ</Link>),
                visible: true
            }, {
                key: "6",
                icon: <BugOutlined />,
                label: (<Link to="/bugreport/" onClick={closeSideBar}>REPORT AN ISSUE</Link>),
                visible: true
            }, {
                key: "7",
                icon: <LogoutOutlined />,
                label: (<Link to={getLogoutUrl()} onClick={logout}>Logout</Link>),
                visible: authenticated
            }, {
                key: "8",
                icon: <UserOutlined />,
                label: (<Link to={getLoginUrl()} onClick={redirectToLogout}>Login</Link>
                ),
                visible: !authenticated
            }, {
                key: "9",
                icon: <UserOutlined />,
                label: (<Link to={getSignUpUrl()} onClick={redirectToLogout}>Sign Up</Link>
                ),
                visible: !authenticated
            },
        ] as any[]

        setFilteredMenuItems(menuItems.filter(i => i.visible).map(i => {
            return {
                key: i.key,
                icon: i.icon,
                label: i.label
            }
        }));

    }, [authenticated]);

    const SideBarContent = () => {
        return (
            <>
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    className="side-bar-menu"
                    items={filteredMenuItems}
                />
            </>
        );
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <Header className="mobile-header fixed-header">
                <div className="left-section">
                    {showBackButton && (
                        <Button
                            type="text"
                            className="back-btn"
                            icon={<ArrowLeftOutlined />}
                            onClick={goBack}
                        />
                    )}

                    {title ? (
                        <div className="mobile-header-title">{title}</div>
                    ) : (
                        <div className="logo">
                            <Link to="/">
                                <Logo className={"header-logo"} />
                            </Link>
                        </div>
                    )}
                </div>

                <div className="right-section">

                    {searchBar && (
                        <Button
                            type="text"
                            icon={searchBarOpen ? <CloseOutlined /> : <SearchOutlined />}
                            onClick={toggleSearch}
                        />
                    )}

                    <Button
                        className="menu-icon"
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={toggleMenu}
                    />
                </div>
            </Header>

            {searchBar && searchBarOpen && (
                <div className="fixed-search-bar">
                    <Input
                        className="search-input"
                        placeholder={`${searchBarText}...`}
                        allowClear
                        onChange={onSearch}
                    />
                </div>
            )}

            <Drawer
                title="Menu"
                placement="right"
                onClose={toggleMenu}
                open={navbarOpen}
                bodyStyle={{ padding: 0 }}
            >
                <SideBarContent />
            </Drawer>
        </>
    );
};
export default MobileHeader;
