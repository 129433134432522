import CollectionsList from "../../Components/CollectionsList/CollectionsList";
import "./../../styles/Title.scss";
import { useState, useEffect, useRef } from "react";
import { API_URL } from "../../const";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { useAppContext } from "../../Context/AppContext";
import { Course } from "../../Models/Course";
import { redirectToLogout } from "../../utils";

const MyCollections: React.FC = () => {
    const { setShowLoader, searchBarOpen } = useAppContext();
    const dataFetchedRef = useRef(true);

    const [collections, setCollections] = useState([]);
    const [filteredCollections, setFilteredCollections] = useState([]);

    const logout = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("userId");
        redirectToLogout();
    };

    const fetchMyCollections = () => {
        setShowLoader(true);
        fetch(
            API_URL +
            `/api/v1/users/${localStorage.getItem(
                "userId"
            )}/purchased-collections/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => response.json())
            .then((data) => {
                // concatonate purchase  and valid collections
                if (data.valid) {
                    const validSet = data.valid.concat(data.purchased);
                    setCollections(validSet);
                    setFilteredCollections(validSet);
                }
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
            })
            .catch(() => {
                logout();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
            });
    };

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            fetchMyCollections();
        }
    }, [dataFetchedRef]);

    // function that handles setting the current value when the search button is clicked.
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        if (!query) {
            setFilteredCollections([...collections]);
            return;
        }

        const tempList = [...collections];
        const searchedCollections = tempList.filter((collection: Course) =>
            collection.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCollections(searchedCollections);
    };

    return (
        <>
            <MobileHeader
                searchBar={true}
                onSearch={handleSearch}
                searchBarText={"Search my courses"}
            />

            <div className={`page ${searchBarOpen ? "search-bar-open" : ""}`}>
                {collections === null ? (
                    <p>No Courses</p>
                ) : (
                    <CollectionsList collections={filteredCollections} />
                )}
            </div>
        </>
    );
};

export default MyCollections;
