import { UpCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import RechargeForm from "./RechargeForm";

interface RechargeButtonProps { }

const RechargeButton: React.FC<RechargeButtonProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Button type="primary" size="middle" className="text-center w-100" onClick={() => setIsOpen(true)} icon={<UpCircleOutlined />}>
                Recharge
            </Button>

            {/* recharge form */}
            {isOpen && <RechargeForm isOpen={isOpen} setIsOpen={setIsOpen} />}
        </>
    )
}

export default RechargeButton;