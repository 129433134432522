import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm';
import { useAppContext } from '../../Context/AppContext';
import { API_URL } from '../../const';
import { User } from '../../Models/User';

interface PaymentContainerProps {
    resetReadyToCheckout: any, cupointsValue: any, currencyValue: any, currency: any
}


// const stripePromise = loadStripe("pk_live_51JDw98H1nFM3sj3kMZjvRxLECvlslHBbiQRPn4W2NpQaE6C38JhDPQxYHE3RDdyHUkJqjuMRD5RLoaHLCohQK6Oj00hXMckgJT");
const stripePromise = loadStripe("pk_test_51QSnjTBFdP30p4OjzmANMHAxg98Bgb0g5Im7qPm6Z6v5WR6zuBAYtatYowaHxj7KXaipXeg38Ky0y6WNdWyoBhQP00QUt87Q4l");

const PaymentContainer: React.FC<PaymentContainerProps> = ({ resetReadyToCheckout, cupointsValue, currencyValue, currency }) => {

    const { setUser, user } = useAppContext();

    const refreshUserWallet = (callback: () => void) => {
        fetch(API_URL + `/api/v1/users/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data: User) => {
                setUser(data);
                callback();
            })
            .catch((error) => {
                console.log("Failed to refresh user wallet")
            });
    };

    return (
        <>
            {user?.primaryWallet.id ? (
                <Elements stripe={stripePromise}>
                    <StripePaymentForm
                        cupointsValue={cupointsValue}
                        currency={currency}
                        currencyValue={currencyValue}
                        resetReadyToCheckout={resetReadyToCheckout}
                        refreshUserWallet={refreshUserWallet}
                        walletId={user?.primaryWallet.id}
                    />
                </Elements>
            ) : (
                <> User wallet not found</>
            )}
        </>
    )
}

export default PaymentContainer;