import React, { useEffect, useRef } from "react";
import "./CourseCertificationPage.scss";
import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { Course, Module } from "../../Models/Course";
import { Access, ModuleAccesses } from "../../Models/ModuleAccess";
import { Alert, Button, Image, Typography } from "antd";
import { FaPlayCircle } from "react-icons/fa";
import { FolderOutlined, PlaySquareOutlined, SafetyCertificateOutlined, ThunderboltOutlined, VideoCameraOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { getCollectionNetPrice, secondsToHms } from "../../utils";
import IntroPreviewer from "../../Components/IntroPreviewer/IntroPreviewer";
import MobileModal from "../../Components/MobileModal/MobileModal";
import ButtonWithImages from "../../Components/ButtonWithImages/ButtonWithImages";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";
import { API_URL } from "../../const";
import { useAppContext } from "../../Context/AppContext";
import FloatingButtons from "../../Components/FloatingButtons/FloatingButtons";
import { Link } from "react-router-dom";
import { CERTIFICATE_STATUS, CertificateInfo } from "../../Models/Certificate";
import CertificationActionButtons from "./CertificationActionButtons";
import { fetchCollection, fetchCollectionCertificationDetails, fetchModuleAccess } from "../../servicecs/collection.service";

const { Paragraph, Text, Title } = Typography;

const CourseCertificationPage: React.FC = () => {
    const { authenticated, setShowLoader, searchBarOpen } = useAppContext();

    const dataFetchedRef = useRef(true);
    let { collectionId } = useParams();

    const [activeTab, setActiveTab] = useState<number>(1);
    const [collection, setCollection] = useState<Course | null>(null);
    const [moduleAccess, setModuleAccess] = useState<{ [key: string]: Access }>({});
    const [filteredModules, setFilteredModules] = useState<Module[]>([]);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>(false);
    const [showFooter, setShowFooter] = useState<boolean>(true);
    const [certificateInfo, setCertificateInfo] = useState<CertificateInfo | null>(null);
    const [collectionNetPrice, setCollectionNetPrice] = useState<number>(0);

    const refreshCollection = (collectionId: number) => {
        fetchCollection(Number(collectionId), authenticated, (data: Course) => {
            setCollection(data);
            if (data) {
                setFilteredModules([...data.modules]);
            }
            refreshCourseCertificateInfo(collectionId);
            refreshModuleAccess(Number(collectionId), data);
        }, (error: any) => { });
    };

    const refreshModuleAccess = (collectionId: number, collection: Course) => {
        fetchModuleAccess(collectionId, (data: ModuleAccesses) => {
            if (data?.moduleAccesses) {
                setModuleAccess(data.moduleAccesses);
            }
            calculateCollectionPrice(collection, data?.moduleAccesses || {});
            setShowLoader(false);
        }, () => {
            calculateCollectionPrice(collection, {});
        });
    };

    const refreshCourseCertificateInfo = (collectionId: number) => {
        fetchCollectionCertificationDetails(collectionId, (data: CertificateInfo) => {
            if (!data) return;

            setCertificateInfo(data);
        })
    }

    const reloadData = () => {
        refreshModuleAccess(Number(collectionId), collection!);
        refreshCourseCertificateInfo(Number(collectionId));
    }

    const calculateCollectionPrice = (course: Course, accessData: { [key: string]: Access }): void => {

        if (!course || !accessData || !course.certification) return;

        const loggedUserIdStr: string | null = localStorage.getItem("userId");
        const isLoggedIn = loggedUserIdStr !== null && Boolean(loggedUserIdStr);

        const collectionPrice = getCollectionNetPrice(course, accessData, isLoggedIn);

        if (!course.promotion) {
            setCollectionNetPrice(collectionPrice + course.certification.price);
            return;
        }

        const promotionDiscount = parseFloat(
            course.promotion.promoDiscount
        );
        setCollectionNetPrice(
            collectionPrice +
            Math.ceil(course.certification.price * (1 - promotionDiscount))
        );
    }

    const handleModuleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!collection) return;

        const query = event.target.value;
        if (!query) {
            setFilteredModules([...collection.modules]);
            return;
        }

        // Filter modules based on query
        const filterd =
            Array.isArray(collection.modules) && collection.modules.length !== 0
                ? [...collection.modules].filter(
                    (module) =>
                        module.name
                            .toLowerCase()
                            .includes(query.toLowerCase()) ||
                        module.videos.some((video) =>
                            video.title
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        )
                )
                : [];

        setFilteredModules(filterd);
    };

    const isNotLocked = (moduleId: number) => {
        const moduleAccessObj = moduleAccess[moduleId];
        if (!moduleAccessObj) return false;

        if (moduleAccessObj.accessType == "permanent") return true;

        if (moduleAccessObj.accessType == "temporary") {
            let currentDate = moment(new Date());
            let openDate = moment(new Date(moduleAccessObj.accessFrom)).startOf(
                "day"
            );
            let closeDate = moment(new Date(moduleAccessObj.accessUntil)).endOf(
                "day"
            );
            return currentDate >= openDate && currentDate < closeDate;
        }

        return false;
    };

    const DummyCertificate = () => {
        return (
            <>
                <div className="dummy-certificate"></div>
            </>
        );
    };

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            refreshCollection(Number(collectionId));
        }
    }, []);

    return (
        <>
            <MobileHeader
                searchBar={true}
                onSearch={handleModuleSearch}
                searchBarText={"Search Modules"}
            />

            {collection !== null ? (
                <>
                    <div className={`page ${searchBarOpen ? 'search-bar-open' : ''} ${showFooter ? 'footer-visible' : ''}`}>
                        <div className="collection-information-wrapper">
                            <div className="image-wrapper">
                                <IntroPreviewer
                                    collection={collection}
                                    videoType={collection?.certification?.promotionVideoType}
                                    videoUrl={collection?.certification?.promotionVideoUrl}
                                    thumbnail={
                                        <Image
                                            src={collection?.certification?.image || collection.coverImage.image}
                                            preview={false}
                                            width={"100%"}
                                            className="myCollection-img"
                                        />
                                    }
                                />
                            </div>
                            <div className="details-wrapper">
                                <h3 className="collection-name">
                                    {collection.name}
                                </h3>
                                <h6 className="collection-author">
                                    By {collection.createdByUsername}
                                </h6>
                                <div className="collection-details-bullets">
                                    {collection?.moduleCount > 0 && (
                                        <span>
                                            <FolderOutlined />
                                            {collection.moduleCount} Modules
                                        </span>
                                    )}
                                    {collection?.videoCount > 0 && (
                                        <span>
                                            <VideoCameraOutlined />
                                            {collection.videoCount} Videos
                                        </span>
                                    )}
                                    {collection?.totalDuration > 0 && (
                                        <span>
                                            <PlaySquareOutlined />
                                            {secondsToHms(
                                                collection.totalDuration || 0,
                                                false
                                            )} total duration
                                        </span>
                                    )}
                                </div>


                                <div className="below-section">

                                    {/* Duration */}
                                    {collection?.certification && certificateInfo &&
                                        certificateInfo.certificateStatus !==
                                        CERTIFICATE_STATUS.ISSUED && (
                                            <div>
                                                <Text color="secondary" className="duration" style={{ marginTop: "0" }}>The course has a{" "}
                                                    {collection.certification.duration}{" "}
                                                    {collection.certification.duration >
                                                        1
                                                        ? "months"
                                                        : "month"}{" "}
                                                    learning period.{" "}
                                                </Text>
                                            </div>
                                        )}

                                    <Alert style={{ marginTop: "8px", padding: "8px" }} description={(
                                        <>
                                            {/* Issuer */}
                                            <div>
                                                <Text className="issuer" style={{ fontSize: "12px", fontWeight: "normal" }}>
                                                    <SafetyCertificateOutlined />{" "}
                                                    <i>
                                                        Certificate offered by CUbits, Inc,
                                                        Princeton University startup
                                                    </i>
                                                </Text>
                                            </div>
                                        </>
                                    )} type="info" />

                                    {/* Show dummy certificate  */}
                                    {certificateInfo &&
                                        certificateInfo.certificateStatus ===
                                        CERTIFICATE_STATUS.ISSUED && (
                                            <Alert
                                                message={"Congratulations!"}
                                                style={{ padding: "0.5rem", fontSize: "12px", marginTop: "0.5rem", marginBottom: "0.5rem" }}
                                                description={
                                                    <>
                                                        You have successfully achieved your{" "}
                                                        <b>{collection.name}</b> Certification.
                                                        Your hard work and dedication have paid
                                                        off. Well done!
                                                    </>
                                                }
                                                type="success"
                                                showIcon
                                                icon={<SafetyCertificateOutlined />}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="button-set">
                            <FloatingButtons>
                                <Button size="small" color="secondary" className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>Certificate</Button>
                                <Button size="small" color="secondary" className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>Modules</Button>
                                {/* <Link to={`/collections/${collection.id}/`}>
                                <Button size="small" color="secondary">Course</Button>
                            </Link> */}
                                {/* <Button size="small" type="primary" color="info" onClick={() => setIsDetailModalVisible(true)} className=""><InfoCircleOutlined /> More Details</Button> */}
                            </FloatingButtons>
                        </div>

                        {/* Collection Details */}
                        {isDetailModalVisible && (
                            <></>
                            // <MobileModal isOpen={isDetailModalVisible} onClose={() => setIsDetailModalVisible(false)} >
                            //     <div className="collection-details-modal-wrapper">
                            //         <Title level={5} color="secondary" className="collection-name">
                            //             {collection.name}
                            //         </Title>
                            //         <h6 className="collection-author">
                            //             By {collection.createdByUsername}
                            //         </h6>
                            //         <div className="collection-details-bullets">
                            //             {collection?.moduleCount > 0 && (
                            //                 <span>
                            //                     <FolderOutlined />
                            //                     {collection.moduleCount} Modules
                            //                 </span>
                            //             )}
                            //             {collection?.videoCount > 0 && (
                            //                 <span>
                            //                     <VideoCameraOutlined />
                            //                     {collection.videoCount} Videos
                            //                 </span>
                            //             )}
                            //             {collection?.totalDuration > 0 && (
                            //                 <span>
                            //                     <PlaySquareOutlined />
                            //                     {secondsToHms(
                            //                         collection.totalDuration || 0,
                            //                         false
                            //                     )} total duration
                            //                 </span>
                            //             )}
                            //         </div>
                            //         <Paragraph className="collection-info-card-description">
                            //             <Text type="secondary" style={{ display: "flex" }}>
                            //                 {collection.description}
                            //             </Text>
                            //         </Paragraph>
                            //         <Paragraph className="collection-info-card-long-description">
                            //             {collection.certification?.description}
                            //         </Paragraph>

                            //         {/* Objectives */}
                            //         {Boolean(collection.certification?.objectives?.length) && (
                            //             <div className="certification-objectives">
                            //                 <Title className="title" level={5}>What you will learn</Title>
                            //                 <ul className="objectives">
                            //                     {collection.certification?.objectives.map(
                            //                         (obj: string, i: number) => (
                            //                             <li key={i}>{obj}</li>
                            //                         )
                            //                     )}
                            //                 </ul>
                            //             </div>
                            //         )}

                            //         {/* Min performance level */}
                            //         {Boolean(collection.certification?.minPerformLevel) && (
                            //             <div className="min-performance-level">
                            //                 <Alert
                            //                     style={{ padding: "1rem .5rem" }}
                            //                     message="Min. Performance Level"
                            //                     description={
                            //                         collection.certification?.minPerformLevel
                            //                     }
                            //                     type="info"
                            //                     showIcon
                            //                 />
                            //             </div>
                            //         )}
                            //     </div>
                            // </MobileModal>
                        )}

                        {/* Tab 1: Certificate Details */}
                        {activeTab === 1 && (
                            <div style={{ padding: "1rem" }}>
                                <Paragraph className="collection-info-card-description">
                                    <Text type="secondary" style={{ display: "flex" }}>
                                        {collection.description}
                                    </Text>
                                </Paragraph>
                                <Paragraph className="collection-info-card-long-description">
                                    {collection.certification?.description}
                                </Paragraph>

                                {/* Objectives */}
                                {Boolean(collection.certification?.objectives?.length) && (
                                    <div className="certification-objectives" style={{
                                        fontSize: "14px",
                                        fontWeight: "normal",
                                        lineHeight: 1.5
                                    }}>
                                        <Title className="title" level={5}>What you will learn</Title>
                                        <ul className="objectives">
                                            {collection.certification?.objectives.map(
                                                (obj: string, i: number) => (
                                                    <li key={i}>{obj}</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}

                                {/* Min performance level */}
                                {Boolean(collection.certification?.minPerformLevel) && (
                                    <div className="min-performance-level">
                                        <Alert
                                            style={{ padding: "1rem .5rem" }}
                                            message="Min. Performance Level"
                                            description={
                                                collection.certification?.minPerformLevel
                                            }
                                            type="info"
                                            showIcon
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Tab 2: Module List */}
                        {activeTab === 2 && (
                            <div className="moduleList">
                                {Array.isArray(collection?.modules) &&
                                    collection.modules.length !== 0 && (
                                        <div className="module-list">
                                            <ul className="button-group">
                                                {filteredModules.map(
                                                    (module, moduleIndex) => (
                                                        <div
                                                            className="collection-module"
                                                            key={moduleIndex}>
                                                            <li className="button-item">
                                                                <ButtonWithImages
                                                                    name={module.name}
                                                                    videos={module.videos}
                                                                    collectionId={
                                                                        collection.id
                                                                    }
                                                                    moduleIndex={
                                                                        moduleIndex
                                                                    }
                                                                    moduleAccess={
                                                                        moduleAccess
                                                                    }
                                                                    locked={
                                                                        !isNotLocked(
                                                                            module.id
                                                                        )
                                                                    }
                                                                />
                                                            </li>
                                                        </div>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                            </div>
                        )}

                    </div>
                    {(certificateInfo && collection && certificateInfo.certificateStatus !== CERTIFICATE_STATUS.ISSUED) && (
                        <div className="footer">
                            <CertificationActionButtons
                                collection={collection}
                                certificateInfo={certificateInfo}
                                collectionNetPrice={collectionNetPrice}
                                onRefreshData={reloadData} />
                        </div>
                    )}
                </>
            ) : (<div className={`page`}>Loading Content...</div>)}
        </>
    );
}

export default CourseCertificationPage;
