import { FC, PropsWithChildren, useState } from "react";
import { PURCHASE_TYPES } from "../../Models/Purchase";
import { Course, Module } from "../../Models/Course";
import { CertificateInfo, Certification } from "../../Models/Certificate";
import MobileModal from "../MobileModal/MobileModal";
import { useAppContext } from "../../Context/AppContext";
import AuthenticationRequired from "../AuthenticationRequired/AuthenticationRequired";
import BalanceInsufficient from "./BalanceInsufficient";
import ContinuePurchase from "./ContinuePurchase";

interface PurchaseModuleProps {
    type: PURCHASE_TYPES,
    netPrice: number,
    onClose: () => void,

    course?: Course,
    module?: Module,
    certification?: Certification,

    onSuccess: (data: any) => void
}

const PurchaseModule: FC<PropsWithChildren<PurchaseModuleProps>> = (props: PropsWithChildren<PurchaseModuleProps>) => {
    const { authenticated, setShowLoader, searchBarOpen, user } = useAppContext();

    const [modalOpen, setModalOpen] = useState(true);

    const onClose = () => {
        setModalOpen(false);
        props.onClose();
    }

    const onSuccess = (data: any) => {
        props.onSuccess(data);
    }
    const onError = () => {

    }

    return (
        <MobileModal isOpen={modalOpen} onClose={onClose}>
            {props.children}
            {authenticated && user ? (
                <>
                    {user.primaryWallet.cupointsBalance >= props.netPrice ? (
                        <ContinuePurchase
                            type={props.type}
                            purchaseAmount={props.netPrice}
                            course={props.course}
                            module={props.module}
                            certification={props.certification}
                            onSuccess={onSuccess}
                            onError={onError}
                        />
                    ) : (
                        <BalanceInsufficient netPrice={props.netPrice} currentBalance={user.primaryWallet.cupointsBalance} />
                    )}
                </>
            ) : <AuthenticationRequired />}
        </MobileModal>
    )
}

export default PurchaseModule;