import { API_URL } from "../const";

export const getNewAccessToken = (authenticatedFunc: () => void) => {
    localStorage.removeItem("access");
    return fetch(API_URL + `/api/v1/token/refresh/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ refresh: localStorage.getItem("refresh") })
    })
        .then((response: any) => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.status);
            }
        })
        .then((data) => {
            if (data != null) {
                localStorage.setItem("access", data["access"]);
                authenticatedFunc();
            }
        })
        .catch((error) => {
            if (parseInt(error.message) === 401) {
                localStorage.removeItem("refresh");
            }
        });
};
