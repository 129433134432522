import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import MobileHeader from "../../Components/MobileHeader/MobileHeader";

const PageNotFoundPage = () => {
    return (
        <>
            <MobileHeader />
            <div className={`page`}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={
                        <Link to="/">
                            <Button type="primary">Back Home</Button>
                        </Link>
                    }
                />
            </div>
        </>
    );
}

export default PageNotFoundPage;
