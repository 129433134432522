import { UpCircleOutlined } from "@ant-design/icons";
import { Button, Col, InputNumber, Radio, RadioChangeEvent, Row, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import MobileModal from "../MobileModal/MobileModal";
import './Wallet.scss';
import { useAppContext } from "../../Context/AppContext";
import AccessDenied from "../AccessDenied/AccessDenied";
import PaymentContainer from "./PaymentContainer";

const { Text } = Typography;

interface RechargeFormProps {
    isOpen: boolean,
    setIsOpen: (open: boolean) => void
}

const RechargeForm: React.FC<RechargeFormProps> = ({ isOpen, setIsOpen }) => {

    const { user } = useAppContext();

    const [value, setValue] = useState<number | null>(null);
    const [selected, setSelected] = useState<number | string | null>(null);
    const [readyToCheckout, setReadyToCheckout] = useState<boolean>(false);

    const calculateCurrencyEquivalent = (amount: number) => {
        if (!user) return 0;

        var amountInCurrency = amount / parseFloat(user.primaryWallet.currency.unitCupoints.toString()) * parseFloat(user.primaryWallet.currency.unitPrice.toString())
        return amountInCurrency / Math.pow(10, parseInt(user.primaryWallet.currency.allowableDecimalPlaces.toString()))
    }

    const onRadioButtonChange = (event: RadioChangeEvent) => {
        if (event.target.value !== -1) {
            setSelected(event.target.value)
            setValue(event.target.value)
        } else {
            setSelected(event.target.value)
            setValue(null)
        }
    }

    const hidePaymentForm = () => {
        setValue(null);
        setSelected(null);
        setReadyToCheckout(false);
        setIsOpen(false);
    }

    const onInputNumberChange = (value: number | null) => {
        setValue(value !== null ? value : null);
    }

    const SelectCupointsSection = () => {
        return (
            <div id='top-up-form'>
                {user && (
                    <>
                        <Text strong>Select a CUpoint amount: </Text>
                        <br />
                        <br />
                        <Radio.Group value={value} onChange={onRadioButtonChange} style={{ width: "100%" }}>
                            <Space direction="vertical" className="w-100">
                                <Radio.Button className="w-100 text-center" value={100}>100 CUPoints ({`${calculateCurrencyEquivalent(100)} ${user.primaryWallet.currency.abbreviation.toString()}`})</Radio.Button>
                                <Radio.Button className="w-100 text-center" value={200}>200 CUPoints ({`${calculateCurrencyEquivalent(200)} ${user.primaryWallet.currency.abbreviation.toString()}`})</Radio.Button>
                                <Radio.Button className="w-100 text-center" value={500}>500 CUPoints ({`${calculateCurrencyEquivalent(500)} ${user.primaryWallet.currency.abbreviation.toString()}`})</Radio.Button>
                                <Radio.Button className="w-100 text-center" value={1000}>1000 CUPoints ({`${calculateCurrencyEquivalent(1000)} ${user.primaryWallet.currency.abbreviation.toString()}`})</Radio.Button>
                                <Radio.Button className="w-100 text-center" value={-1}>Custom</Radio.Button>
                                {selected === -1 ? <InputNumber className="w-100" placeholder="Enter CUPoints Amount"
                                    min={100}
                                    max={10000}
                                    onChange={onInputNumberChange}
                                    value={value}
                                    parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                /> : null}
                            </Space>
                        </Radio.Group>
                        <br />
                        <br />
                        {value ? <>
                            <Text strong>You have selected:</Text>
                            <Row gutter={0}>
                                <Col span={6}>{value} CUpoints</Col>
                                <Col span={2}>=</Col>
                                <Col span={6}>{`${calculateCurrencyEquivalent(value)} ${user.primaryWallet.currency.abbreviation.toString()}`} </Col>
                            </Row>

                        </> : null
                        }
                        <br />
                        <br />
                        <div className='w-100 payButtonSection'>
                            <Button
                                type="primary"
                                disabled={!Boolean(value)}
                                onClick={() =>
                                    setReadyToCheckout(true)
                                }>
                                Continue
                            </Button>
                            <Button type='default' className='w-100'
                                onClick={() => setIsOpen(false)}
                            >Cancel</Button>
                        </div>

                    </>
                )}

            </div>
        )
    }

    return (
        <>
            {user ? (
                <MobileModal isOpen={isOpen} onClose={() => setIsOpen(false)} >
                    {readyToCheckout && value ? <PaymentContainer
                        resetReadyToCheckout={hidePaymentForm}
                        cupointsValue={value}
                        currencyValue={calculateCurrencyEquivalent(value)}
                        currency={user.primaryWallet.currency}
                    /> : <SelectCupointsSection />}
                </MobileModal>
            ) : (
                <AccessDenied />
            )}
        </>
    )
}

export default RechargeForm;