import { API_URL } from "../const";
import { getNewAccessToken } from "./user.service";

export const createPaymentIntent = (
    cupointsValue: number,
    onSuccess: (secret: string) => void
) => {
    return fetch(API_URL + "/api/v1/create-payment-intent/", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ cupoints: cupointsValue })
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            onSuccess(data.clientSecret);
        });
};

export const topUpWallet = (
    walletId: number,
    cupoints: number,
    paymentIntentId: string,
    onSuccess: (data: any) => void,
    onError: (error: any) => void
) => {
    return fetch(API_URL + `/api/v1/wallets/${walletId}/topup/`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            cupoints: cupoints,
            paymentIntentId: paymentIntentId
        })
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                onError(response.status);
            }
        })
        .then((data: any) => {
            onSuccess(data);
        })
        .catch((error) => {
            if (parseInt(error.message) === 401) {
                getNewAccessToken(() =>
                    topUpWallet(
                        walletId,
                        cupoints,
                        paymentIntentId,
                        onSuccess,
                        onError
                    )
                );
            }
        });
};
