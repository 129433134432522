export enum CERTIFICATE_STATUS {
    NOT_REQUESTED = "not_requested",
    REQUESTED = "requested",
    ISSUED = "issued",
    REJECTED = "rejected"
}

export interface CertificateInfo {
    evaluationShow: boolean;
    evaluationExists: boolean;
    evaluationExpired: boolean;
    evaluationEndDate: any;
    evaluationEndsIn: any;
    certificatePurchased: boolean;
    certificatePurchaseDate: any;
    certificateStatus: CERTIFICATE_STATUS;
    certificateEndDate: any;
    certificateEndsIn: any;
    certificateExpired: boolean;
    evaluationProgress?: boolean;
    certificateStatusProgress?: boolean;
}

export interface Certification {
    id: number;
    collection: number;
    description: string;
    image: any;
    promotionVideoType: any;
    promotionVideoUrl: any;
    prerequisites: string[];
    objectives: string[];
    price: number;
    duration: number;
    minPerformLevel: string;
}
