import React, { useEffect, useRef } from "react";
import "./ModuleContent.scss";
import { useParams } from "react-router-dom";
import ButtonWithImages from "./ButtonWithImages/ButtonWithImages";
import { useState } from "react";
import MobileHeader from "./MobileHeader/MobileHeader";
import { API_URL } from "../const";
import moment from "moment";
import { useAppContext } from "../Context/AppContext";
import { Course, Module } from "../Models/Course";
import { Access, ModuleAccesses } from "../Models/ModuleAccess";
import { Button, Card, Image, message, Typography } from "antd";
import { FaPlayCircle } from "react-icons/fa";
import { FolderOutlined, PlaySquareOutlined, ThunderboltOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { getCollectionNetPrice, secondsToHms } from "../utils";
import FloatingButtons from "./FloatingButtons/FloatingButtons";
import IntroPreviewer from "./IntroPreviewer/IntroPreviewer";
import MobileModal from "./MobileModal/MobileModal";
import { Link } from "react-router-dom";
import Cupoints from "./Cupoints/Cupoints";
import PurchaseModule from "./PurchaseModule/PurchaseModule";
import { PURCHASE_TYPES } from "../Models/Purchase";
import { fetchCollection, fetchModuleAccess } from "../servicecs/collection.service";

const { Paragraph, Text, Title } = Typography;

function ModuleContent() {
    const { authenticated, setShowLoader, searchBarOpen } = useAppContext();

    const dataFetchedRef = useRef(true);
    let { collectionId } = useParams();

    const [collection, setCollection] = useState<Course | null>(null);
    const [moduleAccess, setModuleAccess] = useState<{ [key: string]: Access }>({});
    const [filteredModules, setFilteredModules] = useState<Module[]>([]);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState<boolean>(false);
    const [collectionNetPrice, setCollectionNetPrice] = useState<number>(0);
    const [showFooter, setShowFooter] = useState<boolean>(true);
    const [showCoursePurchaseModal, setShowCoursePurchaseModal] = useState<boolean>(false);

    const refreshCollection = (collectionId: number) => {
        fetchCollection(Number(collectionId), authenticated, (data: Course) => {
            setCollection(data);
            if (data) {
                setFilteredModules([...data.modules]);
            }
            refreshModuleAccess(Number(collectionId), data);
        }, (error: any) => { });
    };

    const refreshModuleAccess = (collectionId: number, collection: Course) => {
        fetchModuleAccess(collectionId, (data: ModuleAccesses) => {
            if (data?.moduleAccesses) {
                setModuleAccess(data.moduleAccesses);
            }
            calculateCollectionPrice(collection, data?.moduleAccesses || {});
            setShowLoader(false);
        }, () => {
            calculateCollectionPrice(collection, {});
        });
    };

    const calculateCollectionPrice = (course: Course, accessData: { [key: string]: Access }): void => {

        if (!course || !accessData) return;

        const loggedUserIdStr: string | null = localStorage.getItem("userId");
        const isLoggedIn = loggedUserIdStr !== null && Boolean(loggedUserIdStr);

        const collectionPrice = getCollectionNetPrice(course, accessData, isLoggedIn);
        setCollectionNetPrice(collectionPrice);
    }

    const handleModuleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!collection) return;

        const query = event.target.value;
        if (!query) {
            setFilteredModules([...collection.modules]);
            return;
        }

        // Filter modules based on query
        const filterd =
            Array.isArray(collection.modules) && collection.modules.length !== 0
                ? [...collection.modules].filter(
                    (module) =>
                        module.name
                            .toLowerCase()
                            .includes(query.toLowerCase()) ||
                        module.videos.some((video) =>
                            video.title
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        )
                )
                : [];

        setFilteredModules(filterd);
    };

    const isNotLocked = (moduleId: number) => {
        const moduleAccessObj = moduleAccess[moduleId];
        if (!moduleAccessObj) return false;

        if (moduleAccessObj.accessType == "permanent") return true;

        if (moduleAccessObj.accessType == "temporary") {
            let currentDate = moment(new Date());
            let openDate = moment(new Date(moduleAccessObj.accessFrom)).startOf(
                "day"
            );
            let closeDate = moment(new Date(moduleAccessObj.accessUntil)).endOf(
                "day"
            );
            return currentDate >= openDate && currentDate < closeDate;
        }

        return false;
    };

    const onCoursePurchaseSuccess = (data: any) => {
        refreshModuleAccess(Number(collectionId), collection!);
        setShowCoursePurchaseModal(false);
        message.success(
            "You have purchased collection successfully!"
        );
    }

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            refreshCollection(Number(collectionId));
        }
    }, []);

    return (
        <>
            <MobileHeader
                searchBar={true}
                onSearch={handleModuleSearch}
                searchBarText={"Search Modules"}
            />

            {collection !== null ? (
                <>
                    <div className={`page ${searchBarOpen ? 'search-bar-open' : ''} ${showFooter ? 'footer-visible' : ''}`}>
                        <div className="collection-information-wrapper">
                            <div className="image-wrapper">
                                <IntroPreviewer
                                    collection={collection}
                                    videoType={
                                        collection?.introVideoType
                                    }
                                    videoUrl={
                                        collection?.introVideoUrl
                                    }
                                    thumbnail={
                                        <Image
                                            src={collection.coverImage.image}
                                            preview={false}
                                            width={"100%"}
                                            className="myCollection-img"
                                        />
                                    }
                                />
                            </div>
                            <div className="details-wrapper">
                                <h3 className="collection-name">
                                    {collection.name}
                                </h3>
                                <h6 className="collection-author">
                                    By {collection.createdByUsername}
                                </h6>
                                <div className="collection-details-bullets">
                                    {collection?.moduleCount > 0 && (
                                        <span>
                                            <FolderOutlined />
                                            {collection.moduleCount} Modules
                                        </span>
                                    )}
                                    {collection?.videoCount > 0 && (
                                        <span>
                                            <VideoCameraOutlined />
                                            {collection.videoCount} Videos
                                        </span>
                                    )}
                                    {collection?.totalDuration > 0 && (
                                        <span>
                                            <PlaySquareOutlined />
                                            {secondsToHms(
                                                collection.totalDuration || 0,
                                                false
                                            )} total duration
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="button-set">
                            <FloatingButtons>
                                <Button size="small" color="secondary" className={"active"}>Modules</Button>
                                <Button size="small" color="secondary" onClick={() => setIsDetailModalVisible(true)} className="">More</Button>
                                {collection.certification && (
                                    <Link to={`/collections/${collection.id}/certification/`}>
                                        <Button size="small" className="gold-button">Certification</Button>
                                    </Link>
                                )}
                            </FloatingButtons>
                        </div>

                        {/* Collection Details */}
                        {isDetailModalVisible && (
                            <MobileModal isOpen={isDetailModalVisible} onClose={() => setIsDetailModalVisible(false)} >
                                <div className="collection-details-modal-wrapper">
                                    <Title level={5} color="secondary" className="collection-name">
                                        {collection.name}
                                    </Title>
                                    <h6 className="collection-author">
                                        By {collection.createdByUsername}
                                    </h6>
                                    <div className="collection-details-bullets">
                                        {collection?.moduleCount > 0 && (
                                            <span>
                                                <FolderOutlined />
                                                {collection.moduleCount} Modules
                                            </span>
                                        )}
                                        {collection?.videoCount > 0 && (
                                            <span>
                                                <VideoCameraOutlined />
                                                {collection.videoCount} Videos
                                            </span>
                                        )}
                                        {collection?.totalDuration > 0 && (
                                            <span>
                                                <PlaySquareOutlined />
                                                {secondsToHms(
                                                    collection.totalDuration || 0,
                                                    false
                                                )} total duration
                                            </span>
                                        )}
                                    </div>
                                    <Paragraph className="collection-info-card-description">
                                        <Text type="secondary" style={{ display: "flex" }}>
                                            {collection.description}
                                        </Text>
                                    </Paragraph>
                                    <Paragraph className="collection-info-card-long-description">
                                        {collection.longDescription}
                                    </Paragraph>
                                </div>
                            </MobileModal>
                        )}

                        {/* Module List */}
                        <div className="moduleList">
                            {Array.isArray(collection?.modules) &&
                                collection.modules.length !== 0 && (
                                    <div className="module-list">
                                        <ul className="button-group">
                                            {filteredModules.map(
                                                (module, moduleIndex) => (
                                                    <div
                                                        className="collection-module"
                                                        key={moduleIndex}>
                                                        <li className="button-item">
                                                            <ButtonWithImages
                                                                name={module.name}
                                                                videos={module.videos}
                                                                collectionId={
                                                                    collection.id
                                                                }
                                                                moduleIndex={
                                                                    moduleIndex
                                                                }
                                                                moduleAccess={
                                                                    moduleAccess
                                                                }
                                                                locked={
                                                                    !isNotLocked(
                                                                        module.id
                                                                    )
                                                                }
                                                            />
                                                        </li>
                                                    </div>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                        </div>

                    </div>


                    {collectionNetPrice > 0 && (
                        <>
                            <div className="footer">
                                <Button type="primary" size="middle" className="text-center w-100" icon={<ThunderboltOutlined />} onClick={() => setShowCoursePurchaseModal(true)}>
                                    <Cupoints points={collectionNetPrice} secondaryWithinBrackets={true}>
                                        Purchase all {collectionNetPrice > 0 ? "@" : null}
                                    </Cupoints>
                                </Button>
                            </div>

                            {showCoursePurchaseModal && (
                                <PurchaseModule
                                    type={PURCHASE_TYPES.COURSE}
                                    netPrice={collectionNetPrice}
                                    course={collection}
                                    onClose={() => setShowCoursePurchaseModal(false)}
                                    onSuccess={onCoursePurchaseSuccess}>
                                    <Card>
                                        <Cupoints points={collectionNetPrice} secondaryWithinBrackets={true}>
                                            Purchase all {collectionNetPrice > 0 ? "@" : null}
                                        </Cupoints>
                                    </Card>
                                </PurchaseModule>
                            )}
                        </>
                    )}
                </>
            ) : (<></>)}
        </>
    );
}

export default ModuleContent;
